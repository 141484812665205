<template>
  <div class="main-content" id="content">
    <h3 class="marginBottom20">
      {{ `${type === "add" ? "新增" : type === "edit" ? "编辑" : "查看"}产品` }}
    </h3>
    <!-- <el-card style="padding-left: 50px"> -->
    <el-form
      ref="form"
      :model="form"
      label-position="left"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="产品名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入"
          style="width: 400px"
          maxlength="50"
          :disabled="disabled"
        ></el-input>
      </el-form-item>

      <el-form-item label="产品分类" :span="8" prop="classifyStr">
        <el-cascader
          v-model="form.classifyStr"
          :options="classifyList"
          style="width: 400px"
          :disabled="disabled"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="产品图片" :span="8" prop="image">
        <FormImgUpload
          :url="form.image"
          @upload="uploadImg"
          :limit="1"
          :maxSize="20"
          :accept="'.png,.jpg,.jpeg,.svg,.tiff'"
          :multiple="true"
          :disabled="disabled"
        />
        <div style="color: #c0c1c3">
          建议尺寸：330px*330px，图片格式：.png,.jpg,.jpeg,.svg,.tiff
        </div>
      </el-form-item>

      <el-form-item label="文件" prop="fileUrl">
        <FormFileUpload
          :url="form.fileUrl"
          @upload="upload"
          :disabled="disabled"
          :limit="1"
          :maxSize="500"
          :accept="'.pdf,'"
          :multiple="true"
          :isFileName="form.firmwareName || '上传完成'"
          :showView="true"
        />
        <div style="color: #c0c1c3">文件格式：.pdf</div>
      </el-form-item>
      <el-form-item label="产品描述" prop="name">
        <el-input
          type="textarea"
          v-model="form.productDesc"
          placeholder="请输入"
          style="width: 400px"
          maxlength="500"
          :disabled="disabled"
          :rows="5"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="revert">返回</el-button>
        <el-button type="primary" @click="submit" v-if="!disabled"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
    <!-- </el-card> -->
  </div>
</template>

<script>
import {
  infoSolutionClassify,
  infoProductAdd,
  infoProductUpdate,
  infoProductView,
} from "@/api/info/product";
import FormEditor from "@/components/formComponents/formEditor";
import dayjs from "dayjs";
import FormFileUpload from "@/components/formComponents/formFileUpload";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { ETypeInfo, MTypeInfo, OTypeInfo } from "@/enums/beCurrent/EBeCurrent";

export default {
  name: "create",
  components: {
    FormEditor,
    FormFileUpload,
    FormImgUpload,
  },
  data() {
    return {
      ETypeInfo: ETypeInfo,
      MTypeInfo: MTypeInfo,
      OTypeInfo: OTypeInfo,
      classifyList: [],
      id: this.$route.query.id,
      disabled: this.$route.query.type === "view",
      type: this.$route.query.type,
      form: {
        classifyStr: "",
        productDesc: "",
        name: "",
        fileUrl: [],
        image: [],
      },
      //校验规则
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        classifyStr: [{ required: true, message: "请输入", trigger: "blur" }],
        productDesc: [{ required: true, message: "请输入", trigger: "blur" }],
        image: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error("请上传"));
              } else {
                callback();
              }
            },
          },
        ],
        fileUrl: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error("请上传"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      loadingSubmit: false,
    };
  },
  mounted() {
    this.getClassifyList();
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    async getView() {
      if (this.id) {
        const { data } = await infoProductView(this.id);
        this.form = {
          ...data,
          image: data.image.split(","),
          fileUrl: data.fileUrl.split(","),
          classifyStr: data.classifyStr.split(","),
        };
      }
    },

    async submit() {
      if (this.loadingSubmit) {
        return;
      }
      let newVal = {
        ...this.form,
        classifyId: this.form.classifyStr[1],
        classifyStr: this.form.classifyStr.join(","),
        image: this.form.image.join(","),
        fileUrl: this.form.fileUrl.join(","),
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingSubmit = true;
          if (this.type == "add") {
            infoProductAdd({
              ...newVal,
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                // loading.close();
                // this.$message.error(err.msg);
                this.loadingSubmit = false;
              });
          } else {
            infoProductUpdate({
              ...newVal,
              id: this.id,
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                // loading.close();
                // this.$message.error(err.msg);
                this.loadingSubmit = false;
              });
          }
        }
      });
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.content = e;
    },
    //文件上传
    upload(e, name) {
      if (Array.isArray(e)) {
        this.form.fileUrl = [...e];
      } else {
        this.form.fileUrl = [...this.form.fileUrl, e];
      }
      this.form.firmwareName = name;
    },
    //图片上传
    uploadImg(e) {
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    // 处理树 ，value label
    convertTreeData(tree, valueKey = "id", labelKey = "name") {
      return tree.map((node) => {
        const newNode = {
          value: node[valueKey],
          label: node[labelKey],
          disabled:
            node.parentId == 0 &&
            (!node.children || (node.children && node.children.length == 0)),
        };
        if (node.children) {
          newNode.children = this.convertTreeData(
            node.children,
            valueKey,
            labelKey
          );
        }
        return newNode;
      });
    },
    //权益分类
    getClassifyList() {
      infoSolutionClassify({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.classifyList = this.convertTreeData(res.data);
        }
      });
    },
    revert() {
      this.$router.go(-1);
    },
    // 切换类型
    getType(e) {
      if (e == 1) {
        this.form.content = "";
      } else {
        this.form.content = [];
      }
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-left: 30px;
  padding-right: 40%;
}
</style>
